const getEnvName = () => process.env.NODE_ENV || '';

export const isProduction = () => getEnvName() !== 'development' && getEnvName() !== 'test';

export const getAppUrl = (origin = 'editor-app'): string =>
  isProduction()
    ? `https://static.parastorage.com/services/pay-button-worker/${getAppVersion()}/${origin}.bundle.min.js`
    : `https://localhost:3212/${origin}.bundle.js`;

export const getBaseUrl = () => {
  const [url] = getAppUrl().match(/((?:\/[^\/]+)+)(?=\/[^\/]+)/) || [null];
  return `${(url && url.substring(1)) || ''}`;
};

export const getAppVersion = () => {
  if (isProduction()) {
    return process.env.ARTIFACT_VERSION?.replace('-SNAPSHOT', '');
  }

  return 'local-development';
};

export const getStaticsBaseUrl = () => `${getBaseUrl()}/statics`;
