import { EditorEvent } from '../enums/editorEvents';
import { SetManifestProps } from './manifest.types';

export const setComponentsManifest = ({
  showUpgradeButton,
  translations: { mainAction1Label, mainAction2Label },
}: SetManifestProps): any => ({
  controllersStageData: {
    WixPay: {
      default: {
        gfpp: {
          desktop: {
            mainAction1: { actionId: EditorEvent.OpenPaymentSettings, label: mainAction1Label },
            mainAction2: { actionId: EditorEvent.ConnectPaymentMethods, label: mainAction2Label },
            iconButtons: {
              link: 'HIDE',
              ...(showUpgradeButton
                ? {
                    upgrade: 'SITE_UPGRADE',
                  }
                : {}),
            },
            helpId: 'ff0c64a8-98f0-470a-9135-622f1504de83',
          },
        },
        behavior: {
          rotatable: false,
        },
      },
    },
  },
});
