import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import translations from '../../utils/translations';
import { setComponentsManifest } from '../manifest';
import { buttonStructure } from '../structures/button';
import { getControllerStructure } from '../structures/controller';
import { HandleError } from '../../utils/handleError';
import { Experiment } from '../../enums/Experiment';
import { getPremiumStatus } from '../../api';
import { APP_ID } from '../../constants';
import { execEditorAction } from '../../utils/execEditorAction';

export const getCurrentPage = (editorSDK) => editorSDK.pages.getCurrent();

export const getAppManifest = async (_editorSDK): Promise<any> => {
  let showUpgradeButton = false;
  try {
    const instance = await _editorSDK.info.getAppInstance(APP_ID);
    const isPremium = await getPremiumStatus(instance);

    if (!isPremium) {
      await experimentsService.init();
      showUpgradeButton = experimentsService.isEnabled(Experiment.PayButtonCrownButton);
    }
  } catch (e) {
    console.error(e);
  }

  return setComponentsManifest({
    showUpgradeButton,
    translations: {
      mainAction1Label: translations.t('buttonGFPP.mainAction1'),
      mainAction2Label: translations.t('buttonGFPP.mainAction2'),
    },
  });
};

export async function getChildren(editorSDK, componentRef, appDefinitionId) {
  return editorSDK.components.getChildren(appDefinitionId, { componentRef });
}

export async function addController(editorSDK, appDefinitionId, pageRef) {
  return editorSDK.components.add(appDefinitionId, {
    pageRef,
    role: 'PayButton',
    componentDefinition: getControllerStructure(appDefinitionId, buttonStructure),
  });
}

export async function getButtonText(editorSDK, appDefinitionId, componentRef) {
  const [button] = await getChildren(editorSDK, componentRef, appDefinitionId);
  return editorSDK.document.components.data.get(appDefinitionId, {
    componentRef: button,
  });
}

export async function updateButtonLabel(editorSDK, appDefinitionId, componentRef, label, handleError: HandleError) {
  try {
    const [button] = await getChildren(editorSDK, componentRef, appDefinitionId);
    await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(appDefinitionId, () => {
          return editorSDK.document.components.data.update(appDefinitionId, {
            componentRef: button,
            data: { label },
          });
        }),
    });
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(appDefinitionId, e)) {
      handleError(e as Error, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e as Error, 'Error in updating button label.');
    }
  }
}
