import { createHttpClient } from '@wix/http-client';
import { ORDER_API_PATH, PRODUCT_API_PATH } from './constants';
import { getBusinessDataLink, getPremiumStatusLink } from './utils/links';

const httpClient = createHttpClient();

export const createOrder = (instance, data: { productId: string }) => {
  return httpClient
    .post<{ orderId: string }>(ORDER_API_PATH, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const createProduct = (instance, data) => {
  return httpClient
    .post<{ productId: string }>(PRODUCT_API_PATH, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const getProduct = (instance, productId) => {
  return httpClient
    .get<{ product: { currency: string } }>(`${PRODUCT_API_PATH}/${productId}`, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data);
};

export const updateProduct = (instance, data, productId) => {
  return httpClient
    .put(`${PRODUCT_API_PATH}/${productId}`, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const updateProductCurrencyFromSettings = async (instance, productId) => {
  const [
    product,
    {
      properties: { paymentCurrency: currency },
    },
  ] = await Promise.all([getProduct(instance, productId), getBusinessData(instance)]);
  product.product.currency = currency;
  return updateProduct(instance, product, productId);
};

export const getBusinessData = (instance) => {
  return httpClient
    .get<{ properties: { paymentCurrency: string } }>(getBusinessDataLink(), { headers: { Authorization: instance } })
    .then((resp) => resp.data);
};

export const getPremiumStatus = (instance: string) => {
  return httpClient
    .get(getPremiumStatusLink(), {
      headers: { Authorization: instance },
    })
    .then((resp) => (resp as any).data.canAcceptOnlinePayments)
    .catch(() => true);
};

export async function makeRequestForOrder(instance, productId) {
  return createOrder(instance, { productId });
}
