const getControllerStructure = (appDefinitionId, components) => {
  return {
    componentType: 'platform.components.AppWidget',
    data: {
      controllerType: 'WixPay',
      name: 'WixPay',
      applicationId: appDefinitionId,
    },
    layout: {
      width: 320,
      height: 60,
    },
    components: [components],
  };
};

export { getControllerStructure };
