import { createProduct, getProduct } from '../../api';
import { APP_ID } from '../../constants';
import { HandleError } from '../../utils/handleError';
import { overrideConnectionConfig } from './overrideConnectionConfig';

export const redefineButtonProductId = async (
  { editorSDK, savedProductId, appDefinitionId, controllerRef },
  handleError: HandleError,
): Promise<string> => {
  const [listControllers, instance] = await Promise.all([
    editorSDK.controllers.listAllControllers(),
    editorSDK.info.getAppInstance(APP_ID),
  ]);
  const productIds: string[] = [];
  for (const controller of listControllers) {
    const {
      config: { productId },
    } = await editorSDK.document.controllers.getData('', controller);
    productIds.push(productId);
  }
  if (productIds.filter((id) => savedProductId === id).length > 1) {
    savedProductId = await updateProduct(
      { editorSDK, instance, appDefinitionId, controllerRef, savedProductId },
      handleError,
    );
  }
  return savedProductId;
};

const updateProduct = async (
  { editorSDK, instance, savedProductId, controllerRef, appDefinitionId },
  handleError: HandleError,
) => {
  const productData = await getProduct(instance, savedProductId);
  const { productId: newProductId } = await createProduct(instance, productData);
  await overrideConnectionConfig(editorSDK, appDefinitionId, controllerRef, { productId: newProductId }, handleError);
  return newProductId;
};
