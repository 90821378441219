export type HandleError = (error: Error | ErrorEvent | string, consoleErrorMessage?: string) => void;

export const defaultErrorHandler = (error: Error | ErrorEvent | string, errorMessage?: string) => {
  console.error(errorMessage || error);
  throw error;
};

export const createHandleError = (sentryErrorReporter): HandleError => {
  return (error: Error | ErrorEvent | string, consoleErrorMessage?: string) => {
    sentryErrorReporter(error);
    defaultErrorHandler(error, consoleErrorMessage);
  };
};

export const handleSdkNotInitialized = () => {
  return defaultErrorHandler(new Error('EditorSDK was not initialized.'));
};
