import { factory } from '@wix/web-bi-logger';

export const logger = factory().logger();

export const getBiLoggerParams = async (sdk, component_ref_id) => {
  const [app_id, msid, userGuid] = await Promise.all([
    sdk.document.info.getAppDefinitionId(),
    sdk.document.info.getMetaSiteId(),
    sdk.document.info.getUserId(),
  ]);
  return { app_id, component_ref_id, msid, userGuid };
};
