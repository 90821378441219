import i18n from './i18n';
import { getBaseUrl } from '.';

// import i18n from '../../../utils/i18n'
// import { getBaseUrl } from '../../../utils/utils'

const FALLBACK_TRANSLATIONS_FILE = '//static.parastorage.com/services/pay-button-worker/1.22.0';
const BASE_URL = `https://${getBaseUrl() || FALLBACK_TRANSLATIONS_FILE}/`;

class TranslationsInstance {
  private _t: Function | undefined;

  constructor() {
    this.editorInit = this.editorInit.bind(this);
  }

  async editorInit(locale) {
    const { t } = await i18n({ locale, baseUrl: BASE_URL });
    // con
    this._t = t;
  }

  t = (key, options = {}) => {
    if (!this._t) {
      throw new Error('Translation service was not initialized yet!');
    }
    return this._t(key, options);
  };
}

export default new TranslationsInstance();
