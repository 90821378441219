import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { createProduct } from '../api';
import { APP_ID, createDefaultProduct } from '../constants';
import translations from '../utils/translations';
import { HandleError } from '../utils/handleError';
import { EditorInteractionName } from '../enums/EditorInteractionName';
import { getCurrentPage } from './utils';
import { overrideConnectionConfig } from './utils/overrideConnectionConfig';
import { showSSLEnableNotification } from './utils/showSSLNotification';
import { addControllerAndConnectButton } from './utils/addControllerAndConnectButton';
import { _fedopsLogger } from './editor';

export const editorReady = async (
  editorSDK: IEditorSdk,
  appDefinitionId: string,
  { firstInstall, origin },
  handleError: HandleError,
) => {
  try {
    const [isSSLEnable, locale, currency, pageRef, metaSiteId] = await Promise.all([
      editorSDK.info.isHttpsEnabled(''),
      editorSDK.environment.getLocale(),
      editorSDK.document.info.getCurrency(appDefinitionId),
      getCurrentPage(editorSDK),
      editorSDK.document.info.getMetaSiteId(),
      experimentsService.init(),
    ]);

    await translations.editorInit(locale);

    if (!isSSLEnable) {
      showSSLEnableNotification(editorSDK, appDefinitionId);
    }

    // First install from AppMarket
    if (firstInstall && origin && origin.info && origin.info.type === 'APP_MARKET') {
      const controllerRef = await addControllerAndConnectButton(editorSDK, appDefinitionId, pageRef, handleError);
      try {
        const instance = await editorSDK.info.getAppInstance(APP_ID);
        const resp = await createProduct(instance, createDefaultProduct(currency, translations.t('item.defaultName')));
        const productId = resp.productId;
        await overrideConnectionConfig(
          editorSDK,
          appDefinitionId,
          controllerRef,
          { productId, metaSiteId },
          handleError,
        );
      } catch (e) {
        handleError(e as Error, 'Error while creating a product.');
      }

      await editorSDK.document.save();
    }
    _fedopsLogger.interactionEnded(EditorInteractionName.ALE);
  } catch (error) {
    handleError(error as Error);
  }
};
