import { NO_PAYMENTS_POP_UP_LINK, POP_UP_LINK } from '../constants';

export const getPremiumPageLink = (metaSiteId: string): string =>
  `https://www.wix.com/store/plans?siteGuid=${metaSiteId}&referralAdditionalInfo=payButtonSettingsButton&businessTab=true`;

export const getSettingsLink = (metaSiteId: string, appDefId: string): string =>
  `https://www.wix.com/my-account/settings?metaSiteId=${metaSiteId}&dashboardPath=%2Fsettings%3FapplicationId%3D3991&appDefinitionId=${appDefId}&referralAdditionalInfo=PayButton`;

export const getUpgradeAndTestPaymentModel = (instance: string): string =>
  `${POP_UP_LINK}?instance=${instance}&referralInfo=payApiPaymentsPopup&startLoadTime=${Date.now()}`;

export const getPremiumStatusLink = (): string => `/_serverless/payments-checkout-server/pay-button/premiumStatus`;

export const getBusinessDataLink = (): string =>
  `/_api/site-properties-service/properties?fields.paths=paymentCurrency`;

export const getNoPaymentsPopupUrl = ({
  instance,
  referralInfo,
  startLoadTime,
}: {
  instance: string;
  referralInfo?: string;
  startLoadTime?: number;
}): string =>
  `${NO_PAYMENTS_POP_UP_LINK}?instance=${instance}&referralInfo=${referralInfo}&startLoadTime=${startLoadTime}`;
