import { sleep } from './sleep';

interface ExecEditorActionParams {
  editorSDK;
  appDefinitionId: string;
  action(): Promise<any>;
  maxRetries?: number;
}

export const execEditorAction = async <T extends any>(params: ExecEditorActionParams): Promise<T> => {
  const { editorSDK, appDefinitionId, action, maxRetries = 2 } = params;

  let retries: number = 0;
  let lastConflictError: Error;

  do {
    try {
      return await action();
    } catch (e) {
      const isConflictError = await editorSDK.document.transactions.isConflictError(appDefinitionId, e);
      if (!isConflictError) {
        throw e;
      }

      lastConflictError = e as Error;
      retries += 1;
      await sleep(100);
    }
  } while (retries <= maxRetries);

  throw lastConflictError;
};
