import { buttonRole } from '../structures/button';
import { execEditorAction } from '../../utils/execEditorAction';
import { connectButton } from './connect';
import { addController } from './index';

// {id: "comp-kmmau68m", type: "DESKTOP"}
export interface EditorObjectResponse {
  id: string;
  type: string;
}

export const addControllerAndConnectButton = async (
  editorSDK,
  appDefinitionId,
  pageRef,
  handleError,
): Promise<EditorObjectResponse> => {
  try {
    return await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(
          appDefinitionId,
          async (): Promise<EditorObjectResponse> => {
            const controllerRef = await addController(editorSDK, appDefinitionId, pageRef);
            await connectButton(editorSDK, controllerRef, buttonRole, appDefinitionId);
            return controllerRef;
          },
        ),
    });
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(appDefinitionId, e)) {
      handleError(e, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e, 'Error in adding controller and connecting a button.');
    }
  }

  throw 'Error at addControllerAndConnectButton';
};
