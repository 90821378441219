import translation from '../../utils/translations';
import { openModal } from '../panels';

export const showSSLEnableNotification = async (editorSDK, appDefinitionId) => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId();
  editorSDK.editor
    .showNotification(appDefinitionId, {
      message: translation.t('notificationSSL.title'),
      type: 'warning',
      link: { caption: translation.t('notificationSSL.link') },
    })
    .then((res) => {
      if (res) {
        openModal(editorSDK, appDefinitionId, `https://www.wix.com/dashboard/${metaSiteId}/manage-website/ssl`);
      }
    });
};
