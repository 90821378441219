import * as uniq from 'lodash.uniq';
import { payButtonClickToOpenPaymentSettings } from '@wix/bi-logger-pay-button/v2';
import { createProduct, updateProductCurrencyFromSettings } from '../api';
import { APP_ID, createDefaultProduct, createSettingsPanelConfig, panelHelpId } from '../constants';
import translations from '../utils/translations';
import { logger, getBiLoggerParams } from '../utils/logger';
import { EditorEvent } from '../enums/editorEvents';
import { defaultSentryConfig } from '../utils/sentry';
import { createHandleError, defaultErrorHandler, handleSdkNotInitialized, HandleError } from '../utils/handleError';
import { EditorInteractionName } from '../enums/EditorInteractionName';
import { editorReady } from './editorReady';
import { openConnectPaymentMethodsPanel, openSettingsPanel } from './panels';
import { getAppManifest, getButtonText, updateButtonLabel } from './utils';
import { redefineButtonProductId } from './utils/duplicateCase';
import { overrideConnectionConfig } from './utils/overrideConnectionConfig';
import { getProductIdsList } from './utils/getProductIdsList';
import { IMonitoring } from './utils/monitoring';

let handleError: HandleError = defaultErrorHandler;
let _editorSDK: IEditorSdk;
let _appDefinitionId = '';
export let _fedopsLogger = {
  interactionStarted: (name) => name,
  interactionEnded: (name) => name,
  appLoadStarted: () => undefined,
  appLoaded: () => undefined,
};

export const onEvent = async (event) => {
  const { eventPayload = {} } = event;
  const componentRef = eventPayload.componentRef || eventPayload.compRef;

  try {
    switch (eventPayload.id) {
      case EditorEvent.ConnectPaymentMethods: {
        _fedopsLogger.interactionStarted(EditorInteractionName.OPEN_CONNECT_PAYMENT_METHODS_MODAL);

        if (!_editorSDK) {
          handleSdkNotInitialized();
          return;
        }

        openConnectPaymentMethodsPanel({
          editorSDK: _editorSDK,
          appDefinitionId: _appDefinitionId,
          startLoadTime: Date.now(),
        });
        break;
      }

      case EditorEvent.OpenPaymentSettings: {
        _fedopsLogger.interactionStarted(EditorInteractionName.PAY_BUTTON_OPEN_SETTINGS);

        if (!_editorSDK) {
          handleSdkNotInitialized();
          return;
        }

        const params = await getBiLoggerParams(_editorSDK, componentRef.id);
        logger.report(payButtonClickToOpenPaymentSettings(params));

        let {
          config: { productId: savedProductId },
        } = await _editorSDK.document.controllers.getData(_appDefinitionId, { controllerRef: componentRef });
        savedProductId = await redefineButtonProductId(
          {
            editorSDK: _editorSDK,
            appDefinitionId: _appDefinitionId,
            controllerRef: componentRef,
            savedProductId,
          },
          handleError,
        );
        const instance = await _editorSDK.info.getAppInstance(_appDefinitionId);
        await updateProductCurrencyFromSettings(instance, savedProductId);

        const { label: buttonLabel } = await getButtonText(_editorSDK, _appDefinitionId, componentRef);
        const settings = createSettingsPanelConfig({
          settingsTitle: translations.t('settingsPanel.title'),
          startLoadTime: Date.now(),
          appDefinitionId: _appDefinitionId,
          buttonLabel,
          componentRef,
          productId: savedProductId,
          helpId: panelHelpId,
          instance,
        });
        _editorSDK.editor.openComponentPanel('settings-panel', settings);
        break;
      }

      default: {
        break;
      }
    }
  } catch (e) {
    handleError(e as Error);
  }

  // First install from AddPanel
  if (event.eventType === 'componentAddedToStage') {
    try {
      const currency = await _editorSDK.document.info.getCurrency(_appDefinitionId);
      const metaSiteId = await _editorSDK.document.info.getMetaSiteId();
      const instance = await _editorSDK.info.getAppInstance(APP_ID);
      const resp = await createProduct(instance, createDefaultProduct(currency, translations.t('item.defaultName')));
      const productId = resp.productId;
      await overrideConnectionConfig(
        _editorSDK,
        _appDefinitionId,
        componentRef,
        { productId, metaSiteId },
        handleError,
      );
    } catch (e) {
      handleError(e as Error, 'Error while creating a product.');
    }

    await _editorSDK.document.save();
  }

  if (event.eventType === 'componentDeleted') {
    await _editorSDK.document.save();
  }
};

const initializeFedops = (monitoring: IMonitoring) => {
  try {
    const fedopsLogger = monitoring.createFedopsLogger();
    _fedopsLogger = fedopsLogger('pay-button');
    _fedopsLogger.interactionStarted(EditorInteractionName.ALE);
  } catch (e) {
    handleError(e as Error);
  }
};

const overrideEditorSDKAndAppDefId = (editorSDK, appDefinitionId) => {
  _editorSDK = editorSDK;
  _appDefinitionId = appDefinitionId;
};

const initErrorReporter = async (monitoring, editorSDK: IEditorSdk) => {
  try {
    const userId = await editorSDK.document.info.getUserId();
    const locale = await editorSDK.document.info.getSiteRegion();
    const msId = await editorSDK.document.info.getMetaSiteId();
    const tags = {
      msId,
      userId,
      locale,
    };

    const sentryInstance = monitoring.createSentryMonitorForApp(defaultSentryConfig.dsn, {
      dataCallback: () => ({
        ...defaultSentryConfig.config,
        tags: { ...tags },
      }),
    });

    handleError = createHandleError(sentryInstance.captureException.bind(sentryInstance));
  } catch (e) {
    console.error('Sentry is not initialized', e);
  }
};

export default {
  exports: {
    overrideConnectionConfig: (controllerRef, connectionConfig) =>
      overrideConnectionConfig(_editorSDK, _appDefinitionId, controllerRef, connectionConfig, handleError),
    updateButtonLabel: (componentRef, label) =>
      updateButtonLabel(_editorSDK, _appDefinitionId, componentRef, label, handleError),
    openSettingsPanel: async (appDefId: string) => {
      try {
        // https://wix.slack.com/archives/C4KPAQ33K/p1542103124049300
        await openSettingsPanel(_editorSDK, appDefId);
        const instance = await _editorSDK.info.getAppInstance(APP_ID);
        const productIds = await getProductIdsList(_editorSDK);
        uniq(productIds).forEach((id) => updateProductCurrencyFromSettings(instance, id));
      } catch (e) {
        handleError(e as Error);
      }
    },
  },
  onEvent,
  editorReady: async (editorSDK: IEditorSdk, appDefinitionId: string, { firstInstall, origin, monitoring }) => {
    await initErrorReporter(monitoring, editorSDK);
    overrideEditorSDKAndAppDefId(editorSDK, appDefinitionId);
    initializeFedops(monitoring);
    return editorReady(editorSDK, appDefinitionId, { firstInstall, origin }, handleError);
  },
  getAppManifest: () => {
    return getAppManifest(_editorSDK);
  },
};
