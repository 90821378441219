export async function openSettingsPanel(editorSDK, appDefinitionId) {
  // Changing currency and changing email address are located the same page
  return openDashboardModal(editorSDK, appDefinitionId, 'settings');
}

export async function openConnectPaymentMethodsPanel(params: { editorSDK; appDefinitionId; startLoadTime: number }) {
  return openDashboardModal(
    params.editorSDK,
    params.appDefinitionId,
    `payments?referralInfo=pay-button&startLoadTime=${params.startLoadTime}`,
  );
}

export function openModal(editorSDK, appDefinitionId, url) {
  return editorSDK.editor.openModalPanel(appDefinitionId, {
    url,
    width: '100%',
    height: '100%',
    closeOtherPanels: true,
  });
}

export function openDashboardModal(editorSDK, appDefinitionId, url) {
  return editorSDK.editor.openDashboardPanel(appDefinitionId, {
    url,
    width: '100%',
    height: '100%',
    closeOtherPanels: true,
  });
}
