import { getChildren } from './index';

export const connect = (editorSDK, controllerRef, connectToRef, role, appDefinitionId = 'token') => {
  return editorSDK.controllers.connect(appDefinitionId, {
    connectToRef,
    controllerRef,
    role,
    isPrimary: true,
  });
};
export async function connectButton(editorSDK, controllerRef, buttonRole, appDefinitionId) {
  const [button] = await getChildren(editorSDK, controllerRef, appDefinitionId);
  await connect(editorSDK, controllerRef, button, buttonRole, appDefinitionId);
}
