export const getProductIdsList = async (sdk) => {
  const listControllers = await sdk.controllers.listAllControllers();
  const productIds: string[] = [];
  for (const controller of listControllers) {
    const {
      config: { productId },
    } = await sdk.document.controllers.getData('', controller);
    productIds.push(productId);
  }
  return productIds;
};
