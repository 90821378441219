import { HandleError } from '../../utils/handleError';
import { execEditorAction } from '../../utils/execEditorAction';

export const overrideConnectionConfig = async (
  editorSDK,
  appDefinitionId,
  controllerRef,
  connectionConfig,
  handleError: HandleError,
) => {
  try {
    const { config } = await editorSDK.document.controllers.getData(appDefinitionId, { controllerRef });
    await execEditorAction({
      editorSDK,
      appDefinitionId,
      action: () =>
        editorSDK.document.transactions.runAndWaitForApproval(appDefinitionId, () => {
          return editorSDK.document.controllers.saveConfiguration(appDefinitionId, {
            config: {
              ...config,
              ...connectionConfig,
            },
            controllerRef,
          });
        }),
    });
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(appDefinitionId, e)) {
      handleError(e as Error, 'Error in runAndWaitForApproval method (conflict).');
    } else {
      handleError(e as Error, 'Error in saving configuration.');
    }
  }
};
